//BMD-18357
//Adding Sanitize Url 
const sanitizeUrl = require("@braintree/sanitize-url").sanitizeUrl;

const PrivacyPolicyURL = sanitizeUrl(process.env.VUE_APP_CTL_STATIC_DOMAIN+"/Pages/AboutUs/Legal/PrivacyPolicy/")
const PaymentMethodsURL = sanitizeUrl(process.env.VUE_APP_CTL_STATIC_DOMAIN+"/home/help/pay-bill/methods-accepted-for-bill-payment.html")
const ServiceRestoreURL = sanitizeUrl(process.env.VUE_APP_CTL_STATIC_DOMAIN+"/home/help/account/need-your-service-turned-back-on.html")
const PaymentArrangementsIdentifyURL = sanitizeUrl("/pa/identify")
const AutoPayURL = sanitizeUrl(process.env.VUE_APP_CTL_STATIC_DOMAIN+"/home/help/account/autopay/how-to-enroll-in-autopay.html")
const MyaEntryPointURL = sanitizeUrl(process.env.VUE_APP_DH_DOMAIN)
const RetrieveAmountURL = sanitizeUrl(process.env.VUE_APP_DH_DOMAIN+"/login/retrieveAccountNumber")
const FindAccountNumberURL = sanitizeUrl(process.env.VUE_APP_CTL_STATIC_DOMAIN+"/home/help/account/billing/understanding-your-bill.html")
const PaymentMethodURL = sanitizeUrl(process.env.VUE_APP_CTL_STATIC_DOMAIN+"/home/help/account/pay-bill/different-ways-to-pay-your-bill.html")
const NeedYourServiceURL = sanitizeUrl(process.env.VUE_APP_CTL_STATIC_DOMAIN+"/home/help/account/need-your-service-turned-back-on.html")
const BrightspeedURL = sanitizeUrl(process.env.VUE_APP_BSPD_DOMAIN+"/former-century-link-customers/")
const InternetURL = sanitizeUrl(process.env.VUE_APP_CTL_STATIC_DOMAIN+"/internet/")
const TvURL = sanitizeUrl(process.env.VUE_APP_CTL_STATIC_DOMAIN+"/tv/")
const PhoneURL = sanitizeUrl(process.env.VUE_APP_CTL_STATIC_DOMAIN+"/phone/")
const AccessoriesURL = sanitizeUrl(process.env.VUE_APP_CTL_STATIC_DOMAIN+"/accessories/")
const FiberURL = sanitizeUrl(process.env.VUE_APP_CTL_STATIC_DOMAIN+"/fiber/")
const SmallBusinessURL = sanitizeUrl(process.env.VUE_APP_CTL_STATIC_DOMAIN+"/small-business/")
const BusinessInternetURL = sanitizeUrl(process.env.VUE_APP_CTL_STATIC_DOMAIN+"/small-business/business-internet/")
const BusinessTvURL = sanitizeUrl(process.env.VUE_APP_CTL_STATIC_DOMAIN+"/small-business/business-tv/")
const BusinessPhoneURL = sanitizeUrl(process.env.VUE_APP_CTL_STATIC_DOMAIN+"/small-business/business-phone/")
const BusinessFiberURL = sanitizeUrl(process.env.VUE_APP_CTL_STATIC_DOMAIN+"/small-business/business-fiber/")
const HelpURL = sanitizeUrl(process.env.VUE_APP_CTL_STATIC_DOMAIN+"/home/help.html")
const SupportURL = sanitizeUrl(process.env.VUE_APP_CTL_STATIC_DOMAIN+"/small-business/support.html")
const BusinessLoginURL = sanitizeUrl(process.env.VUE_APP_CTL_STATIC_DOMAIN+"/business/login/#/smb")
const AboutUsURL = sanitizeUrl(process.env.VUE_APP_CTL_STATIC_DOMAIN+"/aboutus.html")
const AboutUsLegalURL = sanitizeUrl(process.env.VUE_APP_CTL_STATIC_DOMAIN+"/aboutus/legal.html")
const LegalURL = sanitizeUrl(process.env.VUE_APP_CTL_STATIC_DOMAIN+"/legal/")
const TariffLibraryURL = sanitizeUrl(process.env.VUE_APP_CTL_STATIC_DOMAIN+"/aboutus/legal/tariff-library.html")
const AboutUsDisabledURL = sanitizeUrl(process.env.VUE_APP_CTL_STATIC_DOMAIN+"/aboutus/community/community-development/programs-for-customers-with-disabilities.html")
const SiteMapURL = sanitizeUrl(process.env.VUE_APP_CTL_STATIC_DOMAIN+"/sitemap.html")
const HomeURL = sanitizeUrl(process.env.VUE_APP_CTL_STATIC_DOMAIN+"/home/")
const OnlineSecurityURL= sanitizeUrl(process.env.VUE_APP_CTL_STATIC_DOMAIN+"/aboutus/legal/online-security.html")
const PrivacyNoticeURL = sanitizeUrl(process.env.VUE_APP_CTL_DOMAIN+"/aboutus/legal/privacy-notice.html")
const PayByPhoneURL = sanitizeUrl(process.env.VUE_APP_CTL_STATIC_DOMAIN+"/home/help/pay-bill/pay-by-phone.html")
const ChatWithUs = sanitizeUrl(process.env.VUE_APP_CTL_STATIC_DOMAIN+"/help/chat/?team1=care&team2=payment")
const QBPIdentify = sanitizeUrl("/qbp/identify")
const EmbargoURL = sanitizeUrl(process.env.VUE_APP_CTL_STATIC_DOMAIN+"/home/help/account/pay-bill/faqs-about-account-changes.html?rid=accountchanges")
const PaymentHistoryURL = sanitizeUrl(process.env.VUE_APP_EAM_DOMAIN+"/eam/redirectEntry.do?target=paymentHistory")
const ctlLogoURL =  sanitizeUrl(process.env.VUE_APP_CTL_STATIC_DOMAIN)
const LearnMoreAutoPayURL = sanitizeUrl(process.env.VUE_APP_CTL_STATIC_DOMAIN+"/home/help/account/autopay.html")
const EmailPreferencesURL = sanitizeUrl(process.env.VUE_APP_DH_DOMAIN+"/settings")
const PayBillViewURL = sanitizeUrl(process.env.VUE_APP_DH_DOMAIN+"/billing")
const PaymentHistoryMya = sanitizeUrl(process.env.VUE_APP_DH_DOMAIN+"/paymentHistory")
const AuthHome = sanitizeUrl(process.env.VUE_APP_DH_DOMAIN+"/home")
const AuthBilling = sanitizeUrl(process.env.VUE_APP_EAM_DOMAIN+"/eam/myBill.do")
const AuthServices = sanitizeUrl(process.env.VUE_APP_DH_DOMAIN+"/services")
const AuthBspdSupport = sanitizeUrl("https://www.brightspeed.com/help.html")
const AuthSupport = sanitizeUrl(process.env.VUE_APP_DH_DOMAIN+"/support")
const AuthSettings = sanitizeUrl(process.env.VUE_APP_DH_DOMAIN+"/settings")
const BrightspeedQbpURL = sanitizeUrl(process.env.VUE_APP_BSPD_DSSP_DOMAIN+"/qbp/identify")
const BrightspeedPaURL = sanitizeUrl(process.env.VUE_APP_BSPD_DSSP_DOMAIN+"/pa/identify")
const PaperlessBillingInfoURL = sanitizeUrl(process.env.VUE_APP_CTL_STATIC_DOMAIN+"/home/help/account/billing/simplify-your-life-with-paperless-billing.html?rid=paperless")
const CtlSignInURL = sanitizeUrl(process.env.VUE_APP_DH_DOMAIN)
const Dhlink = sanitizeUrl(process.env.VUE_APP_DH_DOMAIN)
const AuthBillingWithToken = sanitizeUrl(process.env.VUE_APP_DH_DOMAIN+"/billing")
const isDcoFlagAvailable = "true"
const AddAnAccountNumber = sanitizeUrl(process.env.VUE_APP_DH_DOMAIN+"/AddAnAccountNumber")
const DhJumpOutPaymentPlan = sanitizeUrl(process.env.VUE_APP_DH_DOMAIN+"/Orch/dssh/redirectEntry/jumpOutPaymentPlan")
const JumpOutPayBill = sanitizeUrl(process.env.VUE_APP_DH_DOMAIN+"/Orch/dssh/redirectEntry/jumpOutPayBill")
const PayBillEntryPoint = sanitizeUrl(process.env.VUE_APP_DH_DOMAIN+"/Orch/dssh/entryPoint?target=payBill")
const CtlNetURL = sanitizeUrl("https://centurylink.net/?tour=myaccounttour")
const WalletJSEncrypt = sanitizeUrl(process.env.VUE_APP_HCDE_DOMAIN+ "hcdeui/jswallet/v1/jsencrypt.js")
const hcdeWalletServiceApi = sanitizeUrl(process.env.VUE_APP_HCDE_DOMAIN+ "hcdeui/jswallet/v1/hcdeWalletServiceApi.js")
const hcdeTokenServiceApi = sanitizeUrl(process.env.VUE_APP_HCDE_DOMAIN+ "hcdeui/jstoken/v1/hcdeTokenServiceApi.js")
const hcdePaypalServiceApi = sanitizeUrl(process.env.VUE_APP_HCDE_DOMAIN+ "hcdeui/jspaypal/v1/hcdePaypalServiceApi.js")
const hcdeWalletComponent = sanitizeUrl(process.env.VUE_APP_HCDE_DOMAIN+ "hcdeui/jswallet/v1/hcdeWalletComponent.js")
const hcdeTokenComponent = sanitizeUrl(process.env.VUE_APP_HCDE_DOMAIN+ "hcdeui/jstoken/v1/hcdeTokenComponent.js")
const hcdePaypalComponent = sanitizeUrl(process.env.VUE_APP_HCDE_DOMAIN+ "hcdeui/jspaypal/v1/hcdePaypalComponent.js")
const jsWalletMain = sanitizeUrl(process.env.VUE_APP_HCDE_DOMAIN+ "hcdeui/jswallet/v1/main.js")
const jsTokenMain = sanitizeUrl(process.env.VUE_APP_HCDE_DOMAIN+ "hcdeui/jstoken/v1/main.js")
const jsPayPalMain = sanitizeUrl(process.env.VUE_APP_HCDE_DOMAIN+ "hcdeui/jspaypal/v1/main.js")
const hcdeTokenTheme = sanitizeUrl(process.env.VUE_APP_HCDE_DOMAIN+ "hcdeui/jstoken/v1/hcdeTokenTheme.css")
const hcdewalletTheme = sanitizeUrl(process.env.VUE_APP_HCDE_DOMAIN+ "hcdeui/jswallet/v1/hcdewalletTheme.css")
const hcdePaypalTheme = sanitizeUrl(process.env.VUE_APP_HCDE_DOMAIN+ "hcdeui/jspaypal/v1/hcdePaypalTheme.css")
const TermsandConditions = sanitizeUrl(process.env.VUE_APP_TC+ "resources/CTL_ElectronicAndOnlinePaperlessBillingTermsAndConditions.pdf")
const ControlCenterURL = sanitizeUrl("https://www.lumen.com/login/")
const SetUpProfile = sanitizeUrl(process.env.VUE_APP_DH_DOMAIN + '/login/accountEnrollment')

export default {
    PrivacyPolicyURL: PrivacyPolicyURL,
    PaymentMethodsURL: PaymentMethodsURL,
    ServiceRestoreURL: ServiceRestoreURL,
    PaymentArrangementsIdentifyURL: PaymentArrangementsIdentifyURL,
    AutoPayURL: AutoPayURL,
    MyaEntryPointURL: MyaEntryPointURL,
    RetrieveAmountURL: RetrieveAmountURL,
    FindAccountNumberURL: FindAccountNumberURL,
    PaymentMethodURL: PaymentMethodURL,
    NeedYourServiceURL: NeedYourServiceURL,
    BrightspeedURL: BrightspeedURL,
    BrightspeedQbpURL: BrightspeedQbpURL,
    BrightspeedPaURL: BrightspeedPaURL,
    InternetURL: InternetURL,
    TvURL: TvURL,
    PhoneURL: PhoneURL,
    AccessoriesURL: AccessoriesURL,
    FiberURL: FiberURL,
    SmallBusinessURL: SmallBusinessURL,
    BusinessInternetURL: BusinessInternetURL,
    BusinessTvURL: BusinessTvURL,
    BusinessPhoneURL: BusinessPhoneURL,
    BusinessFiberURL: BusinessFiberURL,
    HelpURL: HelpURL,
    SupportURL: SupportURL,
    BusinessLoginURL: BusinessLoginURL,
    AboutUsURL: AboutUsURL,
    AboutUsLegalURL: AboutUsLegalURL,
    LegalURL: LegalURL,
    TariffLibraryURL: TariffLibraryURL,
    AboutUsDisabledURL: AboutUsDisabledURL,
    SiteMapURL: SiteMapURL,
    HomeURL: HomeURL,
    OnlineSecurityURL: OnlineSecurityURL,
    PrivacyNoticeURL: PrivacyNoticeURL,
    PayByPhoneURL: PayByPhoneURL,
    ChatWithUs: ChatWithUs,
    QBPIdentify: QBPIdentify,
    EmbargoURL: EmbargoURL,
    PaymentHistoryURL: PaymentHistoryURL,
    ctlLogoURL: ctlLogoURL,
    LearnMoreAutoPayURL: LearnMoreAutoPayURL,
    EmailPreferencesURL: EmailPreferencesURL,
    PayBillViewURL: PayBillViewURL,
    PaymentHistoryMya: PaymentHistoryMya,
    AuthHome: AuthHome,
    AuthBilling: AuthBilling,
    AuthServices: AuthServices,
    AuthBspdSupport: AuthBspdSupport,
    AuthSupport: AuthSupport, 
    AuthSettings: AuthSettings,
    PaperlessBillingInfoURL: PaperlessBillingInfoURL,
    CtlSignInURL: CtlSignInURL,
    Dhlink: Dhlink,
    AuthBillingWithToken: AuthBillingWithToken,
    isDcoFlagAvailable: isDcoFlagAvailable,
    AddAnAccountNumber: AddAnAccountNumber,
    DhJumpOutPaymentPlan: DhJumpOutPaymentPlan,
    JumpOutPayBill: JumpOutPayBill,
    PayBillEntryPoint: PayBillEntryPoint,
    CtlNetURL: CtlNetURL,
    WalletJSEncrypt: WalletJSEncrypt,
    hcdeWalletServiceApi: hcdeWalletServiceApi,
    hcdeTokenServiceApi: hcdeTokenServiceApi,
    hcdePaypalServiceApi: hcdePaypalServiceApi,
    hcdeWalletComponent: hcdeWalletComponent,
    hcdeTokenComponent: hcdeTokenComponent,
    hcdePaypalComponent: hcdePaypalComponent,
    jsWalletMain: jsWalletMain,
    jsTokenMain: jsTokenMain,
    jsPayPalMain: jsPayPalMain,
    hcdeTokenTheme: hcdeTokenTheme,
    hcdewalletTheme: hcdewalletTheme,
    hcdePaypalTheme: hcdePaypalTheme,
    TermsandConditions: TermsandConditions,
    ControlCenterURL: ControlCenterURL,
    SetUpProfile: SetUpProfile
    
}
