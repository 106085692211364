const AzureDomainName = process.env.VUE_APP_AZURE_URL
const KubeDomainName = process.env.VUE_APP_KUBE_URL
const DSSPDomainName = process.env.VUE_APP_DSSP_DOMAIN 
const AzureIsAuth = "/dssp/api/isAuth"
const AzureSignIn = "/dssp/api/signin"
const AzureAttrs = "/dssp/api/attrs"
const AzureLogout = "/logout"
const PaymentArrangementIdentifyURL = "/dssp/pa/identify"
const PaymentArrangementPostLoginIdentifyURL = "/dssp/pa/postLoginIdentify"
const QBPIdentifyURL = "/dssp/qbp/identify"
const WallgardenQBPIdentifyURL = "/dssp/wallgarden/identify"
const PaymentSuccessURL = "/dssp/qbp/paymentSuccess"
const SchedulePaymentSuccessURL = "/dssp/pa/payArrangementSuccess"
const PaymentCancelURL = "/dssp/qbp/paymentCancel"
const PaymentArrangementIframeURL = "/dssp/pa/IframeScheduledPmts"
const PaPaymentCancelURL = "/dssp/pa/paymentCancel"
const PaymentFailureCallForOrchestrator = "/dssp/splunk/logger"
const ManageWalletOrchestratorURL = "/dssp/manageWallet/initiate"
const AutopayOrchestratorUrl = "/dssp/autopay/manage"
const AutopayEnrollmentUrl = "/dssp/autopay/success"
const TokenizerURL = "/dssp/token/jumpIn"
const EcomUserProfileURL = "/dssp/profile/getEcomUserProfile"
const SetCurrentAccountURL = "/dssp/profile/setCurrentAccount"
const PostLoginBillPaymentSuccessURL = "/dssp/postbill/paymentSuccess"
const PostLoginBillPaymentEpwfUrl = "/dssp/postbill/paymentDetails"
const SetAutoPayURL = "/dssp/purl/autoPay"
const PaperlessBillingURL = "/dssp/purl/paperlessBilling"
const SchedulePaymentURL = "/dssp/purl/scheduledPayments"
const PaymentArrangementURL = "/dssp/purl/paymentArrangement"
const BilldisputeAccountDetails = "/dssp/billdispute/accountDetails"
const SplunkLogger = "/dssp/splunk/logger"
const BilldisputeInitiateAdjustment = "/dssp/billdispute/initiateAdjustment"
const AutopayReleaseFlag = true
const ACODRegisterationURL = "/dssp/acod/createProfile"
const WalledGardenDecrypt = "/dssp/wallgarden/decrypt"
const WalledGardenDelete = "/dssp/wallgarden/delete"
const SetPaperLessBilling = "/dssp/purl/setpaperlessBilling"
const WalletInitiate= "/dssp/brim/manageWallet/retrieve"
const BrimManageWalletDelete = "/dssp/brim/manageWallet/delete"
const BrimManageWalletUpdate = "/dssp/brim/manageWallet/update"
const BrimManageWalletCreate = "/dssp/brim/manageWallet/create"
const BrimAutopayCustomerDetails = "/dssp/payment/getcpi"
const AutopayEnrollAndDeEnroll = "/dssp/brim/autoPay"
const BrimPaymentPlanCreate = "/dssp/brim/paymentPlan/createPaymentPlan"
const BrimPostBillCreate = "/dssp/brim/authorizePayment/postBill"
const BrimQuickBillPayCreate = "/dssp/brim/authorizePayment/qbp"
const jsencryptHash = "sha256-4jBNjuU9oPFtnVwzqF7Mbew41pRaBu7VUIdlqcZmQwA=";
const hcdeTokenServiceApiHash = "sha256-aODUhBf3Pd34CNR+k3cXVLp1qDRkzuj2WWiNQwTutRs=";
const hcdeTokenComponentHash = "sha256-H6rV2GIq66tLjv2LQOknMQOFEaGd0e5zEQxiHop8PAg=";
const hcdeTokenThemeHash = "sha256-TxHA6ferPBlJHY73XWEF2FGZAnTOIUTyxB3RGtbriuo=";
const hcdeWalletServiceApiHash = "sha256-QAMswuI3z6jWkHXq5aOrSSjErVQzS4NtpRzM1IDaTgc=";
const hcdeWalletComponentHash = "sha256-ZtB/trs2Ljmj+VY1HeGQeMH1KP2TE0JbkYBXC0srnCU=";
const hcdewalletThemeHash = "sha256-glttzEoPHXAOp3jY1cNmudWjtG1HtPsU4eRX6I7PtJQ=";
const hcdePaypalServiceApiHash = "sha256-rSh/ZW5myWtGedFaSwYpNGKEuEi4F63NYlhxDKVcxqs=";
const hcdePaypalComponentHash = "sha256-oxypFPUIlodlSRQtLVi2nV4lFc1JkG5NpjuLhZjxNkw=";
const hcdePaypalThemeHash = "sha256-5SDA0LugaMXq6u6Xo0mFCaY8muH8aBDhpXIB0mdrAMA=";

export default {
    AzureIsAuth: dynamicUrl(AzureIsAuth),
    AzureSignIn: dynamicUrl(AzureSignIn),
    AzureAttrs: dynamicUrl(AzureAttrs),
    AzureLogout: dynamicUrl(AzureLogout),
    PaymentArrangementIdentifyURL: dynamicUrl(PaymentArrangementIdentifyURL),
    PaymentArrangementPostLoginIdentifyURL: dynamicUrl(PaymentArrangementPostLoginIdentifyURL),
    QBPIdentifyURL: dynamicUrl(QBPIdentifyURL),
    WallgardenQBPIdentifyURL: dynamicUrl(WallgardenQBPIdentifyURL),
    PaymentSuccessURL: dynamicUrl(PaymentSuccessURL),
    SchedulePaymentSuccessURL: dynamicUrl(SchedulePaymentSuccessURL),
    PaymentArrangementIframeURL: dynamicUrl(PaymentArrangementIframeURL),
    PaymentCancelURL : dynamicUrl(PaymentCancelURL),
    PaPaymentCancelURL : dynamicUrl(PaPaymentCancelURL),
	PaymentFailureCallForOrchestrator: dynamicUrl(PaymentFailureCallForOrchestrator),
    ManageWalletOrchestratorURL: dynamicUrl(ManageWalletOrchestratorURL),
    AutopayOrchestratorUrl: dynamicUrl(AutopayOrchestratorUrl),
    AutopayEnrollmentUrl: dynamicUrl(AutopayEnrollmentUrl),
    TokenizerURL:dynamicUrl(TokenizerURL),
    EcomUserProfileURL: dynamicUrl(EcomUserProfileURL),
    SetCurrentAccountURL: dynamicUrl(SetCurrentAccountURL),
    PostLoginBillPaymentSuccessURL: dynamicUrl(PostLoginBillPaymentSuccessURL),
    PostLoginBillPaymentEpwfUrl: dynamicUrl(PostLoginBillPaymentEpwfUrl),
    SetAutoPayURL : dynamicUrl(SetAutoPayURL),
    PaperlessBillingURL : dynamicUrl(PaperlessBillingURL),
    SchedulePaymentURL : dynamicUrl(SchedulePaymentURL),
    PaymentArrangementURL : dynamicUrl(PaymentArrangementURL),
    BilldisputeAccountDetails: dynamicUrl(BilldisputeAccountDetails),
    SplunkLogger: dynamicUrl(SplunkLogger),
    BilldisputeInitiateAdjustment : dynamicUrl(BilldisputeInitiateAdjustment),
	DSSPDomainName : DSSPDomainName,
    AutopayReleaseFlag: AutopayReleaseFlag,
    ACODRegisterationURL: dynamicUrl(ACODRegisterationURL),
    WalledGardenDecrypt: dynamicUrl(WalledGardenDecrypt),
    WalledGardenDelete: dynamicUrl(WalledGardenDelete),
    SetPaperLessBilling: dynamicUrl(SetPaperLessBilling),
    WalletInitiate: dynamicUrl(WalletInitiate),
    BrimManageWalletDelete: dynamicUrl(BrimManageWalletDelete),
    BrimManageWalletUpdate: dynamicUrl(BrimManageWalletUpdate),
    BrimManageWalletCreate: dynamicUrl(BrimManageWalletCreate),
    BrimAutopayCustomerDetails: dynamicUrl(BrimAutopayCustomerDetails),
    AutopayEnrollAndDeEnroll: dynamicUrl(AutopayEnrollAndDeEnroll),
    BrimPaymentPlanCreate: dynamicUrl(BrimPaymentPlanCreate),
    BrimPostBillCreate: dynamicUrl(BrimPostBillCreate),
    BrimQuickBillPayCreate: dynamicUrl(BrimQuickBillPayCreate),
    jsencryptHash: jsencryptHash,
    hcdeTokenServiceApiHash: hcdeTokenServiceApiHash,
    hcdeTokenComponentHash: hcdeTokenComponentHash,
    hcdeTokenThemeHash: hcdeTokenThemeHash,
    hcdeWalletServiceApiHash: hcdeWalletServiceApiHash,
    hcdeWalletComponentHash: hcdeWalletComponentHash,
    hcdewalletThemeHash: hcdewalletThemeHash,
    hcdePaypalServiceApiHash: hcdePaypalServiceApiHash,
    hcdePaypalComponentHash: hcdePaypalComponentHash,
    hcdePaypalThemeHash: hcdePaypalThemeHash
}
 export function dynamicUrl(x) {
	if (process.env.VUE_APP_env != "prod" && ( window.location.host.includes(".corp.intranet")|| window.location.host.includes("localhost") )) {
	return KubeDomainName+x;
	}
	return AzureDomainName+x;
	
};