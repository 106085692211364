import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import axios from 'axios'
import constants from "../js/constants";
import store from "../store";
import {
  SET_DATA, GET_DATA
} from "../store/sessioncontants";

import decodeToken from "../js/decodeToken";
import walledGarden from "../js/walledGarden";
import url from 'url';

const attributesData = new Map();
attributesData.set('isAuth', false);

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    redirect: "/qbp/identify"
  },
  {
    path: "/reactivate/lp",
    name: "WalledGardenLP",
    component: () => import(/* webpackChunkName: "about" */ "../views/WalledGardenLP.vue") 
  },
  {
    path: "/reactivate/np",
    name: "WalledGardenNP",
    component: () => import(/* webpackChunkName: "about" */ "../views/WalledGardenNP.vue") 
  },
  {
    path: "/purl/paperlessBilling.do",
    redirect: "/purl/paperlessBilling"
  },
  {
    path: "/qbp/identify",
    name: "Identify",
    component: () => import(/* webpackChunkName: "about" */ "../views/Identify.vue")
  },
  {
    path: "/qbp/payment",
    name: "Confirm",
    component: () => import(/* webpackChunkName: "about" */ "../views/QBPConfirm.vue")
  },
  {
    path: "/pa/identify",
    name: "PaymentArrngmtsIdentify",
    component: () => import(/* webpackChunkName: "about" */ "../views/PaymentArrngmtsIdentify.vue")
  },
  {
    path: "/pa/options",
    name: "PaymentArrngmtsOptions",
    component: () => import(/* webpackChunkName: "about" */ "../views/PaymentArrngmtsOptions.vue")
  },
  {
    path: "/pa/makePayment",
    name: "PAConfirm",
    component: () => import(/* webpackChunkName: "about" */ "../views/PAConfirm.vue")
  },
  {
    path: "/pa/payment/basicSuccess",
    name: "PaymentArrngmtsSuccess",
    component: () => import(/* webpackChunkName: "about" */ "../views/PaymentArrngmtsSuccess.vue")
  },
  {
    path: "/pa/payment/iframeSuccess",
    name: "PaymentArrngmtsSuccessIframe",
    component: () => import(/* webpackChunkName: "about" */ "../views/PaymentArrngmtsSuccessIframe.vue")
  },
  {
    path: "/pa/payment/success",
    name: "PaymentArrngmtsSuccessScheduledPmts",
    component: () => import(/* webpackChunkName: "about" */ "../views/PaymentArrngmtsSuccessScheduledPmts.vue")
  },
  {
    path: "/pa/multiAccount",
    name: "PAMultiAccount",
    component: () => import(/* webpackChunkName: "about" */ "../views/PaymentArrngmtsMultiAccount.vue")
  },
  {
    path: "/pa/postPAIdentify",
    name: "PostPAIdentify",
    component: () => import(/* webpackChunkName: "about" */ "../views/PostPAIdentify.vue")
  },
  {
    path: "/pa/ineligible",
    name: "PaymentArrngmtsIneligibile",
    component: () => import(/* webpackChunkName: "about" */ "../views/PaymentArrngmtsIneligible.vue")
  },
  {
    path: "/qbp/multiAccount",
    name: "MultiAccount",
    component: () => import(/* webpackChunkName: "about" */ "../views/MultiAccount.vue")
  },
  {
    path: "/qbp/promiseToPay",
    name: "QbpPromiseToPay",
    component: () => import(/* webpackChunkName: "about" */ "../views/PromiseToPay.vue")
  },
  {
    path: "/qbp/scheduledToPay",
    name: "QbpScheduledToPay",
    component: () => import(/* webpackChunkName: "about" */ "../views/ScheduledToPay.vue")
  },
  {
    path: '/qbp/payment/success',
    name: 'SuccessfulPayments',
    component:()=> import(/* webpackChunkName: "about" */ '../views/SuccessfulPayments.vue')
  },
  {
    path: '/qbp/payment/cancel',
    name: 'QBPPaymentCancel',
    component:()=> import(/* webpackChunkName: "about" */ '../views/Cancel.vue')
  },
  {
    path: "/pa/payment/cancel",
    name: "PaymentCancel",
    component: () => import(/* webpackChunkName: "about" */ "../views/PaymentArrngmtsCancel.vue")
  },
  {
    path: "/pa/payment/failure/:showTimeout?",
    name: "PaymentArrngmtsFailure",
    component: () => import(/* webpackChunkName: "about" */ "../views/PaymentArrngmtsFailure.vue")
  },
  {
    path: '/qbp/payment/failure/:showTimeout?',
    name: 'FailureOfPayment',
    component: () => import(/* webpackChunkName: "about" */ '../views/FailureOfPayment.vue')
  },
  {
    path: "/autopay/manage",
    name: "ManageAP",
    component: () => import(/* webpackChunkName: "about" */ "../views/ManageAP.vue" )
  },
  {
    path: "/autopay/success",
    name: "SuccessAP",
    component: () => import(/* webpackChunkName: "about" */ "../views/SuccessAP.vue" )
  },
  {
    path: "/autopay/failure/:showTimeout?",
    name: "FailureAP",
    component: () => import(/* webpackChunkName: "about" */ "../views/FailureAP.vue" )
  },
  {
    path: "/autopay/cancel",
    name: "CancelAP",
    component: () => import(/* webpackChunkName: "about" */ "../views/CancelAP.vue" )
  },
  {
    path: "/up/payment/success",
    name: "SuccessUP",
    component: () => import(/* webpackChunkName: "about" */ "../views/SuccessAP.vue" )
  },
  {
    path: "/up/payment/failure",
    name: "FailureUP",
    component: () => import(/* webpackChunkName: "about" */ "../views/FailureAP.vue" )
  },
  {
    path: "/up/payment/cancel",
    name: "CancelUP",
    component: () => import(/* webpackChunkName: "about" */ "../views/CancelAP.vue" )
  },
  {
    path: "/wallet/manage",
    name: "MgeWallet",
    component: () => import(/* webpackChunkName: "about" */ "../views/MgeWallet.vue" )
  },
  {
    path: "/wallet/failure/:showTimeout?",
    name: "FailureMW",
    component: () => import(/* webpackChunkName: "about" */ "../views/FailureMW.vue" )
  },
  {
    path: "/postbill/payment/view",
    name: 'PostBillView',
    component: () => import(/* webpackChunkName: "about" */ "../views/PostBillView.vue")
  },
  {
    path: '/postbill/payment/cancel',
    name: 'PostBillCancel',
    component: () => import(/* webpackChunkName: "about" */ "../views/PostBillCancel.vue")
  },
  {
    path: "/postbill/payment/failure/:showTimeout?",
    name: 'PostLoginBillFailure',
    component: () => import(/* webpackChunkName: "about" */ "../views/PostLoginBillFailure.vue")
  },
  {
    path: "/postbill/payment/success",
    name: 'PostLoginBillSuccess',
    component: () => import(/* webpackChunkName: "about" */ "../views/PostLoginBillSuccess.vue")
  },
  {
    path: "/purl/scheduledPayments",
    name: "ScheduleToPayPurl",
    component: () => import(/* webpackChunkName: "about" */ "../views/PaymentsPurl.vue")
  },
  {
    path: "/purl/paymentArrangement",
    name: "purlPaymentArrangement",
    component: () => import(/* webpackChunkName: "about" */ "../views/PaymentsPurl.vue")
  },
  {
    path: "/purl/paperlessBilling",
    name: "purlpaperbilling",
    component: () => import(/* webpackChunkName: "about" */ "../views/PaymentsPurl.vue")
  },
  {
    path: "/purl/setupAutoPay",
    name: "purlsetupAutoPay",
    component: () => import(/* webpackChunkName: "about" */ "../views/PaymentsPurl.vue")
  },
  {
    path: "/hcdeui/paypal-page",
    name: "paypalRedirect",
    component: () => import(/* webpackChunkName: "about" */ "../views/PaypalRedirect.vue")
  },
  
  // {
  //   path: "/billdispute/reviewbill",
  //   name: "Eligible",
  //   component: () =>
  //     import(/* webpackChunkName: "about" */ "../views/BillDispute.vue")
  // },
  // {
  //   path: "/billdispute/reviewcharges",
  //   name: "LineLevelDetails",
  //   component: () =>
  //     import(/* webpackChunkName: "about" */ "../views/BillDisputeLineLevelDetails.vue")
  // },
  // {
  //   path: "/billdispute/chatwithus",
  //   name: "BillDisputeCancel",
  //   component: () =>
  //     import(/* webpackChunkName: "about" */ "../views/BillDisputeCancel.vue")
  // },
  // {
  //   path: "/billdispute/failure",
  //   name: "BillDisputeFailure",
  //   component: () =>
  //     import(/* webpackChunkName: "about" */ "../views/BillDisputeFailure.vue")
  // },
  // {
  //   path: "/billdispute/ineligible",
  //   name: "BillDisputeInEligible",
  //   component: () =>
  //     import(/* webpackChunkName: "about" */ "../views/BillDisputeInEligible.vue")
  // },
  // {
  //   path: "/billdispute/selectreason",
  //   name: "BillDisputeReason",
  //   component: () =>
  //     import(/* webpackChunkName: "about" */ "../views/BillDisputeReason.vue")
  // },
  // {
  //   path: "/billdispute/success",
  //   name: "BillDisputeSuccess",
  //   component: () =>
  //     import(/* webpackChunkName: "about" */ "../views/BillDisputeSuccess.vue")
  // }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior() {
    return { top: 0 }
  },
});

router.beforeEach((to, from, next) => {
  if (from.path === '/qbp/payment' && to.path === '/pa/ineligible'
    || from.path === '/pa/payment/success' && to.path === '/pa/options'
    || from.path === '/pa/payment/basicSuccess' && to.path === '/pa/options'
    || from.path === '/pa/payment/failure' && to.path === '/pa/options'
    || from.path === '/pa/payment/cancel' && to.path === '/pa/options'
    || from.path === '/' && to.path === '/pa/options'
  ) {
    router.push('/pa/identify');
    next();
  } else if (from.path === '/qbp/payment/success' && to.path === '/qbp/payment'
    || from.path === '/qbp/payment/cancel' && to.path === '/qbp/payment'
    || from.path === '/qbp/payment/failure' && to.path === '/qbp/payment'
    || from.path === '/qbp/payment/success' && to.path === '/qbp/promiseToPay'
    || from.path === '/qbp/payment/cancel' && to.path === '/qbp/promiseToPay'
    || from.path === '/qbp/payment/failure' && to.path === '/qbp/promiseToPay'
    || from.path === '/qbp/payment/success' && to.path === '/qbp/scheduledToPay'
    || from.path === '/qbp/payment/cancel' && to.path === '/qbp/scheduledToPay'
    || from.path === '/qbp/payment/failure' && to.path === '/qbp/scheduledToPay'
    || from.path === '/' && to.path === '/qbp/payment'
    || from.path === '/' && to.path === '/qbp/promiseToPay'
    || from.path === '/' && to.path === '/qbp/scheduledToPay'
  ) {
    router.push('/qbp/identify');
    next();
  } else if (to.path === '/wallet/manage'
    || to.path === '/wallet/failure'
    || to.path === '/autopay/manage'
    || to.path === '/autopay/success'
    || to.path === '/autopay/failure'
    || to.path === '/autopay/cancel'
    || to.path === '/up/payment/success'
    || to.path === '/up/payment/failure'
    || to.path === '/up/payment/cancel'
    || to.path === '/postbill/payment/view'
    || to.path === '/postbill/payment/cancel'
    || to.path === '/postbill/payment/failure'
    || to.path === '/postbill/payment/success'
    || to.path === '/pa/postPAIdentify'
    // || to.path === '/billdispute/reviewbill'
    // || to.path === '/billdispute/reviewcharges'
    // || to.path === '/billdispute/chatwithus'
    // || to.path === '/billdispute/failure'
    // || to.path === '/billdispute/ineligible'
    // || to.path === '/billdispute/selectreason'
    // || to.path === '/billdispute/success'
  ) {
    const token = getToken(to);
      beforeRouteEnter(to, from)
      .then(()=>{
        if (store.getters[GET_DATA]("isAuth")) {
          const wait = decodeToken.decodeToken(token);
          wait.then(function () {
            //  to.path === '/billdispute/reviewbill'
            if((to.path === '/wallet/manage' || to.path === '/autopay/manage' || to.path === '/postbill/payment/view' || to.path === '/pa/postPAIdentify') && (token==null || token=='undefined')){
              window.location.href = process.env.VUE_APP_EAM_DOMAIN+"/eam/myBill.do"
            } else {
              next();
            }
          });
        } else {
            attributesData.set('isAuth', false);
            const postLoginUrl = encodeURIComponent(
              `${constants.DSSPDomainName}${to.path}?token=${token}`
            );
            window.location.href = `${constants.AzureSignIn}?postLoginRedirectURI=${postLoginUrl}`;
        }
      })
      .catch(() => {
        console.log(Error)
      })
  } else if (to.path === '/reactivate/lp'
    || to.path === '/reactivate/np'
  ) {
    const data = store.getters[GET_DATA]("wgData");
    const error = store.getters[GET_DATA]("wgError");
    if (data == null &&  error == null) {
      const queryString = window.location.search;
      const urlParams = new URLSearchParams(queryString);
      const p1 = urlParams.get('p1')
      const p2 = urlParams.get('p2')
      if(p1 != null){
        const wait = walledGarden.decrypt(p1, p2);
        wait.then(function () {
          next();
        });
      }else if(to.path === '/reactivate/np'){
        next();
      }
    } else if (data != null) {
      next();
    }
  } else {
    next();
  }
  
});

function getToken(to) {
  /**
   * Do not use query to get token. it will automatically convert to url decode which will broke the token
   */
  const token = to.query.token != null ? to?.query?.token : to?.params?.token;
  if (token!=null && token != "") {
    const query = url.parse(to.fullPath).query;
    const matches = query.match("token=([^&#]*)");
    return matches[1];
  }
  return token;
}

async function beforeRouteEnter(to, from) {
  try{
    const checkHeaderResponse = await axios.get(constants.AzureIsAuth)
        if (checkHeaderResponse.data) {
          attributesData.set('isAuth', true);
          store.dispatch(SET_DATA, {attributes:attributesData});
        } 
  } catch (error) {
    console.log(error);
  }
}

export default router;
